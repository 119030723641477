import React, { useState, useContext } from "react";
import styles from "../../assets/css/addChild.module.css";
import { Row, Col } from "react-bootstrap";
import useInput from "../../Hooks/useInputHook";
import { useNavigate } from "react-router-dom";
import HobbiesComponent from "../HobbiesComponent";
import ChildrenContext from "../../Engines/ChildrenContext";

const educationLevels = [
  { name: "select level", selected: true },
  { name: "primary" },
  { name: "secondary" },
  { name: "institute" },
];

export default function AddChildPage() {
  const navigate = useNavigate();
  const { updateChildren } = useContext(ChildrenContext);
  const [childFirstName, setChildFirstName, clearChildFirstName] = useInput();
  const [childLastName, setChildLastName, clearChildLastName] = useInput();
  const [childOtherName, setChildOtherName, clearChildOtherName] = useInput();
  const [childId, setChildId, clearChildId] = useInput();
  const [childBirthday, setChildBirthday, clearChildBirthday] = useInput();
  const [
    childEducationLevel,
    setChildEducationLevel,
    clearChildEducationLevel,
  ] = useInput();
  const [childLocation, setChildLocation, clearChildLocation] = useInput();
  const [childStory, setChildStory, clearChildStory] = useInput();
  const [hobbies, setHobbies] = useState([]);
  const [image, setImage] = useState("");

  const pullInHobbies = (hobs) => {
    setHobbies(hobs);
  };

  const handleAddChild = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "ffchildren");
    try {
      const sendImage = await fetch(
        "https://api.cloudinary.com/v1_1/djjriwywb/image/upload",
        { method: "post", body: formData }
      );
      await sendImage.json().then((data) => {
        pullInHobbies();
        const newChild = {
          level: childEducationLevel,
          childID: childId,
          firstName: childFirstName,
          lastName: childLastName,
          otherName: childOtherName,
          birthDay: childBirthday,
          Location: childLocation,
          childPhoto: data.url,
          hobbies,
          story: childStory,
          childPhotoId: data.public_id,
          sponsored: false,
        };

        clearChildFirstName();
        clearChildLastName();
        clearChildOtherName();
        clearChildId();
        clearChildBirthday();
        clearChildEducationLevel();
        clearChildLocation();
        clearChildStory();
        updateChildren(newChild);
        navigate("/");
      });
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  return (
    <div className={styles.addachildPage}>
      <div className={styles.article}>
        <form onSubmit={handleAddChild}>
          <div className={styles.childInformation}>
            <h4 className={styles.sectionHeader}>Child Information</h4>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>First Name*</label>
                <input
                  type="text"
                  required
                  minLength="3"
                  value={childFirstName}
                  onChange={setChildFirstName}
                />
              </Col>
              <Col className={styles.formInputs} lg="6">
                <label>Last Name</label>
                <input
                  type="text"
                  value={childLastName}
                  onChange={setChildLastName}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>Other Name</label>
                <input
                  type="text"
                  value={childOtherName}
                  onChange={setChildOtherName}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>Child ID</label>
                <input
                  type="text"
                  required
                  value={childId}
                  onChange={setChildId}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>BirthDay</label>
                <input
                  type="text"
                  required
                  value={childBirthday}
                  onChange={setChildBirthday}
                  placeholder="Enter date like 2009/02/23"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label htmlFor="education">Education Level*</label>
                <select
                  value={childEducationLevel}
                  onChange={setChildEducationLevel}
                  id="education"
                  required
                >
                  {educationLevels.map((option) => (
                    <option
                      key={option.name}
                      value={option.name}
                      defaultValue={option.selected}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label> Child Location</label>
                <input
                  type="text"
                  placeholder="Kampala, Uganda"
                  value={childLocation}
                  onChange={setChildLocation}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Child Story</label>
                <textarea
                  rows="7"
                  value={childStory}
                  required
                  onChange={setChildStory}
                  placeholder="You must enter a child's story"
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Attach Child Photo</label>
                <input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Hobbies</label>
                <HobbiesComponent
                  pullInHobbies={pullInHobbies}
                  savedHobbies={[]}
                />
              </Col>
            </Row>
          </div>

          <button className={styles.addChildButton}>Submit this Child</button>
        </form>
      </div>
    </div>
  );
}
