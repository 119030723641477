import React from "react";
import Children from "../Children";
import headerImage from "../../assets/images/sponsorheader.jpg";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../../assets/css/childrenPage.module.css";

export default function ChildrenPage({ setChildHandler }) {
  return (
    <div>
      <Container className={styles.childrenPageHeader}>
        <Row>
          <Col lg="4">
            <div className={styles.headerImageWrapper}>
              <img src={headerImage} alt="children header" />
            </div>
          </Col>
          <Col lg="8">
            <div className={styles.headerText}>
              <p>
                Friends For Life rescues vulnerable children and places them in
                a loving and caring environment. Our goal is not only to meet
                their physical needs, but to give them everything they need to
                become healthy and whole, and live a life of purpose.
              </p>
              <p>
                When you sponsor a child, they receive clothing, medical care,
                education and a safe place they can call home.
              </p>
              <p>
                Check out the stories of some of our kids and young adults
                waiting to be sponsored. Sign up today and make a lasting impact
                in their lives. The cost per month depends on the educational
                level of the child, not their age.
              </p>
              <ul>
                <li>PRIMARY LEVEL- $33 CDN/month</li>
                <li>SECONDARY LEVEL- $44 CDN/month</li>
                <li>POST SECONDARY TRAINING/EDUCATION- $55 CDN/month</li>
              </ul>
              <p>Thank you for joining with us to bring help and hope!</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Children setChildHandler={setChildHandler} />
    </div>
  );
}
