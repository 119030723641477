import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap/'
import styles from '../assets/css/children.module.css'
import ChildCard from '../Components/ChildCard'
import ChildStory from '../Components/ChildStory'
import RemoveaChild from '../Containers/RemoveaChild'
import Overlay from '../utils/Overlay'
import ChildrenContext from '../Engines/ChildrenContext'
import Spinner from '../Components/Spinner'

export default function Children() {
  const { children, currUser } = useContext(ChildrenContext)
  const [openStory, SetOpenStory] = useState(false)
  const [openRemoveAChild, setOpenRemoveAChild] = useState(false)
  const [child, setChild] = useState({})
  const [childtodelte, setChildId] = useState('')

  const openChildStoryHandler = (id) => {
    setChild(...children.filter((child) => child._id === id))
    SetOpenStory(true)
  }

  const closeChildStoryHandle = () => {
    SetOpenStory(false)
    setChild({})
  }

  const handleOpenDeletemodal = (childtodelte) => {
    setOpenRemoveAChild(!openRemoveAChild)

    setChildId(childtodelte)
  }
  const handleCancelDeleteaChild = (option) => {
    setOpenRemoveAChild(option)
  }

  return (
    <div className={styles.childrenWrapper}>
      <Container>
        <Row>
          {(children.length === 0 || !children) && <Spinner />}
          {children.map((child) => (
            <Col
              className={styles.childCardHolder}
              lg="3"
              sm="6"
              key={child._id}
            >
              <ChildCard
                child={child}
                openChildStoryHandler={openChildStoryHandler}
                handleOpenDeletemodal={handleOpenDeletemodal}
                currUser={currUser}
              />
            </Col>
          ))}
        </Row>
        {openStory && (
          <Overlay>
            <ChildStory
              hideSponsor={false}
              child={child}
              closeChildStoryHandle={closeChildStoryHandle}
            />
          </Overlay>
        )}

        {openRemoveAChild && (
          <Overlay>
            <RemoveaChild
              handleCancelDeleteaChild={handleCancelDeleteaChild}
              childtodelte={childtodelte}
            />
          </Overlay>
        )}
      </Container>
    </div>
  )
}
