import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import fflLogo from "../assets/images/ffl-logo.svg";
import styles from "../assets/css/navbar.module.css";

function NavScrollExample() {
  const scrollabeNav = [
    // "me - auto",
    "ms - auto",
    "my - 2",
    "my - lg - 0]",
    styles.scrollableLinks,
  ];
  return (
    <Navbar id="fixedNav" expand="lg" className={styles.navbarMain}>
      <Container>
        <Navbar.Brand href="https://friendsforlifeug.org">
          <img src={fflLogo} alt="ffl logo" className={styles.brandLogo} />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          className={styles.myToggler}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className={scrollabeNav} navbarScroll>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://friendsforlifeug.org/"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://friendsforlifeug.org/our-story/"
            >
              Our Story
            </Nav.Link>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://friendsforlifeug.org/our-projects/"
            >
              Our Projects
            </Nav.Link>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://friendsforlifeug.org/get-involved/"
            >
              Get Involved
            </Nav.Link>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://friendsforlifeug.org/contact-us/"
            >
              Contact Us
            </Nav.Link>
          </Nav>

          <a
            className={styles.donate}
            href="https://www.paypal.com/donate/?hosted_button_id=WSJJR7M3CRE6Q"
            variant="outline-success"
          >
            Donate now
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
