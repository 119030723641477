import React, { useState, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from '../assets/css/childCard.module.css'
import { calculateAge, checkBirthday } from '../Engines/DateEngine'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

export default function ChildCard({
  child,
  openChildStoryHandler,
  handleOpenDeletemodal,
  currUser,
}) {
  const [sponsorStatus, setSponsorStatus] = useState(child.sponsored)
  const childCardColors = sponsorStatus
    ? [styles.childCard, styles.childCardSponsored]
    : styles.childCard

  return (
    <Card className={childCardColors}>
      <Card.Img
        variant="top"
        src={child.childPhoto}
        className={styles.childCardImage}
      />
      <Card.Body>
        <Card.Title className={styles.childCardTitle}>
          {child.firstName}
        </Card.Title>
        <Card.Text>
          {checkBirthday(child.birthDay)
            ? `${child.birthDay}`
            : `${calculateAge(child.birthDay)} Years old`}
        </Card.Text>
        <Button
          className={styles.childCardStory}
          onClick={() => openChildStoryHandler(child._id)}
        >
          <FontAwesomeIcon icon={faBook} className={styles.storyIcon} />
          {child.firstName}'s story
        </Button>
        {!child.sponsored ? (
          <Link
            className={styles.childCardButton}
            variant="primary"
            to={`/sponsorachild/${child._id}`}
          >
            Sponsor Now
          </Link>
        ) : (
          <button className={styles.childCardButton} disabled>
            Sponsored
          </button>
        )}
      </Card.Body>
    </Card>
  )
}
