import React, { memo, useState, useEffect, useCallback } from 'react'
import ChildrenPage from './pages/ChildrenPage'
import axios from 'axios'
import SponsorPage from './pages/SponsorPage'
import AddChildPage from './pages/addChildPage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import styles from '../assets/css/sponsorSite.module.css'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
import { Route, Routes } from 'react-router-dom'
import ChildrenContext from '../Engines/ChildrenContext'
import ScrollToTop from '../utils/scrolltoTop'

import Navbar from './Navbar'
import EditChildPage from './pages/EditChildPage'

export default memo(function Sponsorsite() {
  const [children, setChildren] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const updateChildren = async (newChild) => {
    setChildren([...children, newChild])

    try {
      await axios.post(
        'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/Friends for life Uganda/addachild',
        newChild,
      )
    } catch (error) {
      console.error('Error adding document', error)
    }
  }

  const fetchdata = useCallback(async () => {
    try {
      const data = await axios.get(
        'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/Friends for life Uganda',
      )
      const children = data.data[0].children.sort(
        (a, b) => a.sponsored - b.sponsored,
      )
      setChildren([...children])
      setIsFetching(false)
    } catch (error) {
      console.log("Document doesn't exisit", error)
    }
  })

  useEffect(() => {
    if (isFetching) {
      fetchdata()
    }
  }, [fetchdata, isFetching])

  return (
    <ChildrenContext.Provider value={{ children, updateChildren }}>
      <ScrollToTop />
      <div className={styles.minNav}>
        <a href="https://friendsforlifeug.org/our-story/#">
          <FaInstagram className={styles.socialLinks} />
        </a>
        <a href="https://friendsforlifeug.org/our-story/#">
          <FaFacebookF className={styles.socialLinks} />
        </a>
        <a href="https://friendsforlifeug.org/our-story/#">
          <FaTwitter className={styles.socialLinks} />
        </a>
      </div>
      <Navbar />
      <div className={styles.main}>
        <Routes>
          <Route path="/" exact element={<ChildrenPage />} />
          <Route path="/addnewchild" exact element={<AddChildPage />} />
          <Route path="/login" exact element={<LoginPage />} />
          <Route
            path="/registeruser673570217983"
            exact
            element={<RegisterPage />}
          />
          <Route path="/reset" exact element={<ResetPasswordPage />} />
          <Route
            path="/sponsorachild/:childId"
            exact
            element={<SponsorPage />}
          />
          <Route
            path="/sponsorachild/:editChildId/edit"
            exact
            element={<EditChildPage />}
          />
          <Route path="*" />
        </Routes>
      </div>
    </ChildrenContext.Provider>
  )
})
