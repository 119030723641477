import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Sponsorsite from './Containers/Sponsorsite'
import { BrowserRouter } from 'react-router-dom'
require('dotenv').config()

function App() {
  return (
    <BrowserRouter>
      <Sponsorsite />
    </BrowserRouter>
  )
}

export default App
